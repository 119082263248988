import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const AccordionCss = withTheme(() => (
  <Global
    styles={css`
      .background-color-dark {
        .leshen-accordion {
          button[data-open='false'] {
            color: #ffffff !important;
          }
          button[data-open='true'] {
            color: #1990ff !important;
          }
        }
      }
    `}
  />
))

export default AccordionCss
