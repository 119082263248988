import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const SplitContentCss = withTheme(() => (
  <Global
    styles={css`
      .split-content {
        &.hide-tablet-image {
          // hide from 540px to 767px
          @media (min-width: 540px) and (max-width: 767px) {
            .split-content__image {
              display: none;
            }
          }
        }

        &.slanted {
          position: relative;

          .triangle {
            clip-path: polygon(0 0, 100% 0, 100% 30%, 0% 100%);
            display: block;
            width: 50%;
            height: 50%;
            position: absolute;
            bottom: 0;
            background-color: #020c70;
            z-index: -1;
            left: 0;
          }

          .wrapper {
            @media (max-width: 767px) {
              .split-content__grid {
                gap: 0;
              }

              .leshen-split-content {
                padding: 0;
                background-color: #fff !important;
              }

              .split-content__content {
                padding: 32px;
                background-color: #02168b;
              }
            }

            @media (min-width: 768px) {
              background-color: #02168b;
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 76%);

              .leshen-split-content {
                padding-bottom: 130px !important;
              }

              .desktop-image {
                img {
                  transform: translateY(130px);
                }
              }
            }

            @media (min-width: 1200px) {
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 76%);

              .leshen-split-content {
                padding-bottom: 88px !important;

                .desktop-image {
                  img {
                    transform: translateY(80px);
                  }
                }
              }
            }
          }
        }
      }

      // had to change to display flex because grid mobile styles were
      // not working on staging
      .reverse-desktop {
        .split-content__grid {
          display: flex !important;
          flex-direction: column-reverse;
          justify-content: center;
          padding-bottom: 0 !important;

          .split-content__image {
            align-self: center !important;
          }

          @media screen and (min-width: 640px) {
            flex-direction: row;
          }
        }
      }

      .image-bottom-fix {
        .desktop-image {
          display: flex;
          @media (max-width: 768px) {
            display: flex !important;
          }
        }
      }
    `}
  />
))

export default SplitContentCss
