import React from 'react'
import { withTheme } from '@emotion/react'
import HeaderStyles from './Header.styles'
import FooterStyles from './Footer.styles'
import GlobalCss from './GlobalCss.styles'
import ColumnsCss from './Columns.styles'
import BillboardCss from './Billboard.styles'
import SplitContentCss from './SplitContent.styles'
import AccordionCss from './Accordion.styles'
import FormCss from './Form.styles'

const GlobalStyles = withTheme(() => (
  <>
    <GlobalCss />
    <ColumnsCss />
    <BillboardCss />
    <SplitContentCss />
    <AccordionCss />
    <FormCss />
    <HeaderStyles />
    <FooterStyles />
  </>
))

export default GlobalStyles
