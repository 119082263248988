import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const FooterStyles = withTheme(() => (
  <Global
    styles={css`
      img[alt='Logo'] {
        display: block;
        width: 155px;
        height: 30px;
      }

      .leshen-footer {
        a {
          color: black;
        }
      }
    `}
  />
))

export default FooterStyles
