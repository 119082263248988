import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const FormCss = withTheme(() => (
  <Global
    styles={css`
      .hubspot-form {
        max-width: 600px !important;
        margin: 0 auto;
      }
    `}
  />
))

export default FormCss
