import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const BillboardCss = withTheme(() => (
  <Global
    styles={css`
      .slanted {
        &.bg-primary-mobile {
          @media (max-width: 939px) {
            background-color: #02168b !important;
          }
        }

        .leshen-billboard {
          isolation: isolate;
          overflow: hidden;

          .content {
            background-color: unset;
            box-shadow: unset;

            h1,
            h4,
            p {
              color: #fff;
            }
          }

          @media (max-width: 540px) {
            padding-bottom: 0;
          }

          @media (max-width: 939px) {
            img {
              width: 100%;
              transform: translateY(4px);
            }
          }

          @media (min-width: 940px) {
            padding-bottom: 37% !important;
            position: relative;

            .content {
              transform: translateY(100px);
            }

            &::after {
              content: '';
              display: block;
              width: 500px;
              height: 1000px;
              position: absolute;
              bottom: -130px;
              background-color: #000d71;
              z-index: -1;
              left: 0;
              transform: translateX(-300%) rotate(69deg);
              animation: slideIn 0.4s linear;
              animation-delay: 1s;
              animation-fill-mode: forwards; // Added this property to hold the end position of animation
            }

            @keyframes slideIn {
              0% {
                transform: translateX(-300%) rotate(69deg);
              }
              100% {
                transform: translateX(0) rotate(69deg);
              }
            }
          }
        }
      }

      .no-padding-bottom-mobile {
        @media screen and (max-width: 539px) {
          padding-bottom: 0 !important;

          img {
            position: relative;
            bottom: -4px;
          }
        }
      }

      .image-contain {
        object-fit: contain !important;
      }
    `}
  />
))

export default BillboardCss
